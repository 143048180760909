<script>
  import SubPageLayout from 'src/pc/layouts/SubPageLayout'
  import DisplayContainer from '../components/DisplayContainer'
  export default {
    extends: SubPageLayout,
    components: {
      DisplayContainer
    }
  }
</script>

<style scoped>

  .tit_count {
    text-align: center;
  }
  .tit_count .count_box {
    font-family: "Oswald", 'Malgun Gothic', 'Dotum', sans-serif;
    font-size: 0;
  }
  .tit_count .count_box i {
    display: inline-block;
    width: 50px;
    height: 50px;
    line-height: 47px;
    margin-right: 6px;
    font-size: 40px;
    color: #fff;
    font-weight: 500;
    background: #ff6e61;
    border-radius: 100%;
  }
  .tit_count .count_box .count {
    display: inline-block;
    width: 50px;
    height: 50px;
    padding-top: 7px;
    color: #ff6e61;
    border: 2px solid #ff6e61;
    border-radius: 100%;
  }
  .tit_count .count_box .count b {
    display: block;
    height: 20px;
    line-height: 20px;
    font-size: 20px;
  }
  .tit_count .count_box .count span {
    height: 14px;
    line-height: 14px;
    font-size: 9px;
    font-weight: 500;
    letter-spacing: -0.5px;
  }
  .tit_count .ctry_kr {
    line-height: 39px;
    margin-top: 10px;
    font-size: 30px;
    color: #222;
    font-weight: 700;
    letter-spacing: -0.8px;
  }
  .tit_count .ctry_en {
    line-height: 27px;
    font-family: "Oswald", 'Malgun Gothic', 'Dotum', sans-serif;
    font-size: 18px;
    color: #ff6e61;
    font-weight: 500;
  }
  .editer_recmd {
    position: relative;
    margin-top: 24px;
    background: #ff6e61;
  }
  .editer_recmd a {
    display: block;
    height: 300px;
    padding: 30px 530px 30px 30px;
  }
  .editer_recmd .img {
    position: absolute;
    right: 0;
    top: 0;
  }
  .editer_recmd .tit {
    display: inline-block;
    height: 22px;
    line-height: 21px;
    padding: 0 10px 0 7px;
    font-size: 12px;
    color: #fff;
    font-style: italic;
    font-weight: 500;
    background: #243745;
  }
  .editer_recmd .subj {
    line-height: 32px;
    margin-top: 16px;
    font-size: 24px;
    color: #fff;
    font-weight: 600;
    letter-spacing: -0.5px;
  }
  .editer_recmd .writer {
    position: absolute;
    left: 0;
    bottom: 30px;
    width: 310px;
    line-height: 30px;
    text-align: right;
  }
  .editer_recmd .writer .name {
    display: inline-block;
    font-family: "Oswald", 'Malgun Gothic', 'Dotum', sans-serif;
    font-size: 16px;
    color: #fff;
    font-weight: 300;
  }
  .editer_recmd .writer .name:before {
    display: inline-block;
    line-height: 26px;
    margin-right: 4px;
    padding-top: 3px;
    font-size: 13px;
    content: "By";
  }
  .editer_recmd .writer .w_img {
    display: inline-block;
    overflow: hidden;
    border-radius: 100%;
  }
  .lst_area .lst_top:after {
    display: block;
    clear: both;
    content: "";
  }
  .lst_area .by {
    float: left;
    line-height: 40px;
    font-family: "Oswald", 'Malgun Gothic', 'Dotum', sans-serif;
    font-size: 20px;
    color: #222;
    font-weight: 300;
  }
  .lst_area .by b {
    font-weight: 400;
  }
  .lst_area .search_area {
    float: right;
    position: relative;
  }
  .lst_area .search_area .sch_open {
    display: block;
    cursor: pointer;
    width: 42px;
    height: 40px;
    font-size: 0;
    text-indent: -9999px;
    background: url('/statics/img/icon_zoom.png');
    transition: all 0.15s;
  }
  .lst_area .search_area .sch_box {
    position: absolute;
    right: 0;
    top: 0;
    width: 240px;
    height: 40px;
    padding-left: 42px;
    background: #fff url('/statics/img/icon_zoom.png') no-repeat left center;
  }
  .lst_area .search_area .sch_box input {
    width: 100%;
    height: 40px;
    line-height: 40px;
    padding-right: 10px;
    font-size: 16px;
    font-style: italic;
    font-weight: 700;
    color: #333;
    border: 0;
  }
  .lst_area .search_area .sch_box input::-webkit-input-placeholder {
    color: #777;
  }
  .lst_area .search_area .sch_box input:-moz-placeholder {
    color: #777;
  }
  .lst_area .search_area .sch_box input::-moz-placeholder {
    color: #777;
  }
  .lst_area .search_area .sch_box input:-ms-input-placeholder {
    color: #777;
  }
  .c_lst {
    margin-top: 10px;
  }
  .c_lst li {
    position: relative;
    height: 280px;
    margin-top: 10px;
    background: #fff;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  }
  .c_lst li:first-child {
    margin-top: 0;
  }
  .c_lst li:before {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    border: 1px solid #ff6e61;
    opacity: 0;
    transition: all 0.15s;
    content: "";
  }
  .c_lst li:hover:before {
    opacity: 1;
  }
  .c_lst .img {
    position: absolute;
    left: 20px;
    top: 20px;
    z-index: 1;
    transition: all 0.15s;
  }
  .c_lst .img:hover {
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  }
  .c_lst .data {
    position: relative;
    padding: 36px 40px 0 300px;
  }
  .c_lst .category {
    position: absolute;
    right: 560px;
    top: 40px;
    z-index: 2;
    height: 22px;
    line-height: 22px;
    padding: 0 9px 0 8px;
    font-size: 12px;
    color: #fff;
    font-style: italic;
    font-weight: 500;
    background: #ff6e61;
    transition: all 0.15s;
  }
  .c_lst .category:hover {
    background-color: #ff3f2e;
  }
  .c_lst .subj {
    line-height: 34px;
    font-size: 26px;
    color: #000;
    font-weight: 700;
    letter-spacing: -0.5px;
    transition: all 0.15s;
  }
  .c_lst .subj:hover {
    color: #ff6e61;
  }
  .c_lst .date_area {
    line-height: 30px;
    margin-top: 6px;
    font-family: "Oswald", 'Malgun Gothic', 'Dotum', sans-serif;
    font-weight: 300;
  }
  .c_lst .date_area:after {
    display: block;
    clear: both;
    content: "";
  }
  .c_lst .date_area i {
    float: left;
  }
  .c_lst .date_area .date {
    line-height: 26px;
    padding-top: 3px;
    margin-right: 10px;
    font-size: 13px;
    color: #777;
  }
  .c_lst .date_area .writer {
    margin-right: 8px;
    font-size: 16px;
    color: #222;
  }
  .c_lst .date_area .writer:before {
    display: inline-block;
    line-height: 26px;
    padding-top: 3px;
    margin-right: 4px;
    font-size: 13px;
    color: #777;
    content: "BY";
  }
  .c_lst .date_area .writer_img {
    width: 30px;
    height: 30px;
    border-radius: 100%;
    overflow: hidden;
  }
  .c_lst .con {
    line-height: 22px;
    margin-top: 27px;
    font-size: 14px;
    color: #222;
  }
  .c_lst .hv {
    line-height: 20px;
    margin-top: 12px;
  }
  .c_lst .hv:after {
    display: block;
    clear: both;
    content: "";
  }
  .c_lst .h_tags {
    float: left;
    width: calc(100% - 110px);
  }
  .c_lst .h_tags:after {
    display: block;
    clear: both;
    content: "";
  }
  .c_lst .h_tags a {
    float: left;
    margin-right: 10px;
    font-size: 13px;
    color: #ff6e61;
    font-style: italic;
    font-weight: 500;
  }
  .c_lst .h_tags a:hover {
    text-decoration: underline;
  }
  .c_lst .view {
    float: right;
    font-size: 13px;
    color: #222;
    font-style: italic;
  }
  .c_lst .view i {
    font-style: italic;
  }
  .ad_bottom {
    height: 100%;
    width: 100%;
    max-width: 100vw !important;
    display: inline-block;
    margin: 0 !important;
    padding: 0 !important;
  }
  .ad_bottom .mt30 {
    margin-top: 30px;
  }
  .ad_bottom .corp_top5 {
    padding-bottom: 0 !important;
  }
  .ad_bottom .corp_top5 h4:before {
    content: "C";
  }
  .ad_bottom .corp_top5 .lst {
    margin-top: 12px;
  }
  .ad_bottom .corp_top5 .lst li {
    border-bottom: 1px solid #ddd;
  }
  .ad_bottom .corp_top5 .lst a {
    display: table;
    width: 100%;
    cursor: pointer;
  }
  .ad_bottom .corp_top5 .lst a > div {
    display: table-cell;
    vertical-align: middle;
  }
  .ad_bottom .corp_top5 .lst .card_img {
    position: relative;
    text-align: center;
    width: 60px;
    height: 60px;
  }
  .ad_bottom .corp_top5 .lst .card_img:before {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: #efefea;
    border-radius: 100%;
    content: "";
  }
  .ad_bottom .corp_top5 .lst .card_img .img {
    position: relative;
    z-index: 1;
  }
  .ad_bottom .corp_top5 .lst .data_area {
    padding: 0 0 0 20px;
  }
  .ad_bottom .corp_top5 .lst .data_area .num {
    line-height: 18px;
    font-family: "Oswald", 'Malgun Gothic', 'Dotum', sans-serif;
    font-size: 22px;
    color: #ff6e61;
    font-weight: 500;
    display: table-cell;
    vertical-align: middle;
  }
  .ad_bottom .corp_top5 .lst .data_area .name {
    line-height: 19px;
    margin-top: 7px;
    font-size: 15px;
    color: #333;
    letter-spacing: -0.5px;
    font-weight: 500;
    display: table-cell;
    vertical-align: middle;
    padding-left: 12px;
  }
  .ad_bottom .corp_top5 .lst a:hover .name {
    text-decoration: underline;
  }
  .ad_bottom .corp_top5 .more {
    display: block;
    text-align: center;
    cursor: pointer;
    height: 62px;
    line-height: 60px;
    font-size: 16px;
    color: #ff6e61;
    letter-spacing: -0.5px;
    font-weight: 400;
  }
  .ad_bottom .corp_top5 .more i:after {
    display: inline-block;
    width: 6px;
    height: 12px;
    margin: 24px 0 0 10px;
    background: url('/statics/img/icon_arr_right_orange.png') no-repeat;
    background-size: 100% 100%;
    content: "";
  }
  .ad_bottom .corp_top5 .more:hover {
    text-decoration: underline;
  }
  .ad_bottom .rgt_lst {
    margin-top: 30px;
    padding: 20px 20px 30px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
    background: #fff;
  }
  .ad_bottom .rgt_lst h4 {
    height: 22px;
    line-height: 22px;
    font-family: "Noto Sans KR";
    font-size: 23px;
    font-weight: 700;
    letter-spacing: -0.03em;
  }
  .ad_bottom .rgt_lst h4:before {
    display: inline-block;
    width: 22px;
    height: 22px;
    margin: 2px 8px 0 0;
    text-align: center;
    font-family: "Oswald", 'Malgun Gothic', 'Dotum', sans-serif;
    font-size: 16px;
    color: #fff;
    font-weight: 400;
    background: #243745;
    border-radius: 100%;
    content: "C";
  }
  .ad_bottom .rgt_lst h4 b {
    margin-right: 10px;
    margin-left: 8px;
    font-family: "Oswald", 'Malgun Gothic', 'Dotum', sans-serif;
    font-size: 24px;
    color: #000;
    font-weight: 600;
    letter-spacing: -0.8px;
  }
  .ad_bottom .rgt_lst h4 i {
    font-size: 16px;
    color: #666;
    font-style: italic;
    font-weight: 500;
    letter-spacing: -0.8px;
  }
  .ad_bottom .editor_pick h4:before {
    content: "P";
  }
  .ad_bottom .editor_pick .lst li {
    margin-top: 28px;
  }
  .ad_bottom .editor_pick .lst li:first-child {
    margin-top: 20px;
  }
  .ad_bottom .editor_pick .lst .s_tit {
    line-height: 20px;
    margin-top: 13px;
    font-size: 13px;
    color: #777;
  }
  .ad_bottom .editor_pick .lst .img img {
    width: 100% !important;
    max-width: 420px;
  }
  .ad_bottom .editor_pick .lst .tit {
    line-height: 1.4em;
    margin-top: 4px;
    font-size: 15px;
    color: #333;
    letter-spacing: -0.5px;
    font-weight: 500;
  }
  .ad_bottom .editor_pick .lst a:hover .tit {
    color: #ff6e61;
  }
  .ad_bottom .popular_contents h4:before {
    content: "C";
  }
  .ad_bottom .popular_contents .lst {
    margin-top: 2px;
  }
  .ad_bottom .popular_contents .lst li {
    border-bottom: 1px solid #ddd;
  }
  .ad_bottom .popular_contents .lst a {
    position: relative;
    display: table;
    width: 100%;
    height: 130px;
    padding: 15px 0 15px 120px;
  }
  .ad_bottom .popular_contents .lst a .img {
    position: absolute;
    left: 0;
    top: 15px;
  }
  .ad_bottom .popular_contents .lst a .txt {
    display: table-cell;
    vertical-align: middle;
  }
  .ad_bottom .popular_contents .lst a .s_tit {
    line-height: 20px;
    font-size: 13px;
    color: #777;
  }
  .ad_bottom .popular_contents .lst a .tit {
    line-height: 20px;
    margin-top: 5px;
    font-size: 15px;
    color: #333;
    letter-spacing: -0.3px;
    font-weight: 500;
  }
  .ad_bottom .popular_contents .lst a:hover .tit {
    color: #ff6e61;
  }
  .ad_bottom .ad {
    margin: 50px 30px !important;
  }
</style>