var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('page-layout',{staticClass:"video"},[_c('div',{staticClass:"video_player"},[_c('iframe',{attrs:{"src":_vm.main_video_src,"title":"YouTube video player","frameborder":"0","allow":"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture","allowfullscreen":""}})]),_c('div',{staticClass:"box_basic subscribe"},[_c('a',{staticClass:"subsc_btn",attrs:{"href":"https://www.youtube.com/channel/UCsaJhHWL9GHEKfgdrBbw2YA?sub_confirmation=1","target":"_blank"}},[_vm._v("Subscribe Our Channel")])]),_c('div',{staticClass:"curtn_lst"},[_c('div',{staticClass:"box_basic box_tit"},[_c('p',{staticClass:"tit_l"},[_vm._v("🔥 HOT VIDEO 🎥")]),_c('display-container',{attrs:{"section":155,"align":"left","no-auto-link":true},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var component = ref.component;
var row = ref.row;
return _c('a',{staticClass:"video_curtn",on:{"click":function($event){return _vm.showVideo(row.model_data)},"load":function($event){return _vm.showVideo(row.model_data)}}},[_c('p',{staticClass:"img"},[_c('img',{attrs:{"src":component.getImage(row)}})]),_c('p',{staticClass:"subj"},[_vm._v(_vm._s(component.getTitle(row)))])])}}])}),_c('display-container',{attrs:{"section":156,"align":"left","no-auto-link":true},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var component = ref.component;
var row = ref.row;
return _c('a',{staticClass:"video_curtn",on:{"click":function($event){return _vm.showVideo(row.model_data)},"load":function($event){return _vm.showVideo(row.model_data)}}},[_c('p',{staticClass:"img"},[_c('img',{attrs:{"src":component.getImage(row)}})]),_c('p',{staticClass:"subj"},[_vm._v(_vm._s(component.getTitle(row)))])])}}])}),_c('display-container',{attrs:{"section":157,"align":"left","no-auto-link":true},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var component = ref.component;
var row = ref.row;
return _c('a',{staticClass:"video_curtn",on:{"click":function($event){return _vm.showVideo(row.model_data)},"load":function($event){return _vm.showVideo(row.model_data)}}},[_c('p',{staticClass:"img"},[_c('img',{attrs:{"src":component.getImage(row)}})]),_c('p',{staticClass:"subj"},[_vm._v(_vm._s(component.getTitle(row)))])])}}])})],1)]),_c('div',{staticClass:"lst_cate lst_cate_video box_basic"},[_c('a',{class:{on : !_vm.type},on:{"click":function($event){return _vm.setType('')}}},[_vm._v("ALL")]),_c('a',{class:{on : _vm.type === 'HOTEL'},on:{"click":function($event){return _vm.setType('HOTEL')}}},[_vm._v("HOTEL")]),_c('a',{class:{on : _vm.type === 'FLIGHT'},on:{"click":function($event){return _vm.setType('FLIGHT')}}},[_vm._v("FLIGHT")])]),_c('div',{staticClass:"box_basic video_lst",staticStyle:{"min-height":"calc(130px * 5)"}},[_c('q-infinite-scroll',{attrs:{"offset":250},on:{"load":_vm.onLoad},scopedSlots:_vm._u([{key:"loading",fn:function(){return [_c('div',{staticClass:"row justify-center q-my-md"},[_c('q-spinner-dots',{attrs:{"color":"primary","size":"40px"}})],1)]},proxy:true}])},_vm._l((_vm.videoData),function(video){return _c('a',{key:("" + _vm.type + (video.idx)),staticClass:"video_ctnr",on:{"click":function($event){return _vm.showVideo(video)}}},[_c('p',{staticClass:"img"},[_c('img',{attrs:{"src":video.thumbnail}})]),_c('p',{staticClass:"subj"},[_vm._v(_vm._s(video.title))])])}),0)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }