<script>
	import Video from 'src/pc/pages/Video'
  import PageLayout from '../layouts/PageLayout'
  import DisplayContainer from '../components/DisplayContainer'
  export default {
    extends: Video,
    components: {
      PageLayout,
			DisplayContainer
    }
  }
</script>

<style scoped>
  .video_player {
    position: relative;
    height: 0;
    padding-bottom: 56.25vw;
  }
  .video_player iframe {
    position: absolute;
    width: 100%;
    height: 100%;
  }
  .subscribe {
    text-align: center;
    padding: 25px 32.5px 0;
    margin-bottom: 0;
    border-bottom: 0;
  }
  .subscribe .subsc_btn {
    display: inline-block;
    padding: 10px 20px;
    border-radius: 5px;
    background: #ee0000;
    font-size: 13px;
    font-weight: 500;
    color: #fff;
    line-height: 21px;
  }
  .subscribe .subsc_btn:before {
    content: "";
    display: inline-block;
    width: 20px;
    height: 20px;
    background: url('/statics/img/sns_youtube_white.png');
    background-size: cover;
    background-position: center;
    margin-right: 10px;
  }
  .curtn_lst .box_tit {
    padding-bottom: 30px;
    border-bottom: 8px solid #f2f2f2;
  }
  .curtn_lst .box_tit .tit_l {
    margin-bottom: 10px;
  }
  .curtn_lst .video_curtn {
    display: inline-block;
    padding: 7.5px 0;
  }
  .curtn_lst .video_curtn .img {
    display: table-cell;
  }
  .curtn_lst .video_curtn .img img {
    display: table-cell;
    width: calc(50vw - 40px);
    height: calc((50vw - 40px) * 0.56);
  }
  .curtn_lst .video_curtn .subj {
    display: table-cell;
    vertical-align: middle;
    padding-left: 15px;
    font-size: 13px;
    letter-spacing: -0.03em;
  }
  .lst_cate_video {
    border-bottom: 2px solid #f2f2f2;
  }
  .video_lst {
    padding-top: 25px;
  }
  .video_lst .video_ctnr {
    display: inline-block;
    width: calc(50vw - 40px);
    padding-bottom: 22px;
  }
  .video_lst .video_ctnr:nth-child(odd) {
    margin-right: 7.5px;
  }
  .video_lst .video_ctnr:nth-child(even) {
    margin-left: 7.5px;
  }
  .video_lst .video_ctnr .img img {
    width: calc(50vw - 40px);
    height: calc((50vw - 40px) * 0.56);
  }
  .video_lst .video_ctnr .subj {
    font-size: 13px;
    letter-spacing: -0.03em;
    margin-top: 10px;
    word-break: break-all;
  }
</style>