var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('page-layout',{staticClass:"hotel fr-view"},[_c('div',{staticClass:"box_basic box_sch"},[_c('p',{staticClass:"tit_l"},[_vm._v("호텔 검색")]),_c('p',{staticClass:"btn ipt_area",class:{ sel: _vm.getSearchHotelQuery.region.length > 0 || _vm.getSearchHotelQuery.cate },on:{"click":function($event){_vm.searchHotelDialog.region = true}}},[_vm._v(_vm._s(_vm.getRegionText))]),_c('p',{staticClass:"btn ipt_theme",class:{ sel: _vm.getSearchHotelQuery.hashTags.length > 0 },on:{"click":function($event){_vm.searchHotelDialog.hashTags = true}}},[_vm._v(_vm._s(_vm.getHashTagText))]),_c('input',{staticClass:"ipt_city",attrs:{"type":"text","readonly":"readonly","placeholder":"호텔명으로 찾기"},domProps:{"value":_vm.getSearchHotelQueryKeyword},on:{"click":function($event){_vm.searchHotelDialog.keyword = true}}}),_c('a',{staticClass:"btn_confirm",attrs:{"disabled":_vm.isDisabledSearchHotel},on:{"click":function($event){return _vm.goSearchHotel()}}},[_vm._v("검색")]),_c('a',{staticClass:"btn_reset",on:{"click":function($event){return _vm.resetSearchHotel()}}},[_vm._v("검색 초기화")])]),_c('div',{staticClass:"box_basic con_circle"},[_c('p',{staticClass:"tit_l"},[_vm._v("이곳으로 떠나보세요!")]),_c('div',{staticClass:"scroller"},[_c('div',{staticClass:"inner inner_xs num-5"},[_c('display-container',{attrs:{"section":67,"align":"left"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var component = ref.component;
var row = ref.row;
return _c('a',{staticClass:"container ctnr_xs"},[_c('p',{staticClass:"img_wrap bdr_circle"},[_c('img',{attrs:{"src":component.getImage(row)}})]),_c('p',{staticClass:"tit"},[_vm._v(_vm._s(component.getTitle(row)))])])}}])}),_c('display-container',{attrs:{"section":68,"align":"left"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var component = ref.component;
var row = ref.row;
return _c('a',{staticClass:"container ctnr_xs"},[_c('p',{staticClass:"img_wrap bdr_circle"},[_c('img',{attrs:{"src":component.getImage(row)}})]),_c('p',{staticClass:"tit"},[_vm._v(_vm._s(component.getTitle(row)))])])}}])}),_c('display-container',{attrs:{"section":69,"align":"left"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var component = ref.component;
var row = ref.row;
return _c('a',{staticClass:"container ctnr_xs"},[_c('p',{staticClass:"img_wrap bdr_circle"},[_c('img',{attrs:{"src":component.getImage(row)}})]),_c('p',{staticClass:"tit"},[_vm._v(_vm._s(component.getTitle(row)))])])}}])}),_c('display-container',{attrs:{"section":70,"align":"left"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var component = ref.component;
var row = ref.row;
return _c('a',{staticClass:"container ctnr_xs"},[_c('p',{staticClass:"img_wrap bdr_circle"},[_c('img',{attrs:{"src":component.getImage(row)}})]),_c('p',{staticClass:"tit"},[_vm._v(_vm._s(component.getTitle(row)))])])}}])}),_c('display-container',{attrs:{"section":71,"align":"left"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var component = ref.component;
var row = ref.row;
return _c('a',{staticClass:"container ctnr_xs"},[_c('p',{staticClass:"img_wrap bdr_circle"},[_c('img',{attrs:{"src":component.getImage(row)}})]),_c('p',{staticClass:"tit"},[_vm._v(_vm._s(component.getTitle(row)))])])}}])})],1)])]),_c('div',{staticClass:"banner_gosty"},[_c('div',{staticClass:"pc"},[_c('a',{attrs:{"href":"https://www.gosty.co.kr/boardingpass?utm_source=prestigegorilla&utm_medium=banner&utm_campaign=web","target":"_blank"}},[_c('img',{attrs:{"src":"https://cdn.prestigegorilla.net/post/2272/contents/19775/gosty_pc.jpg"}})])]),_c('div',{staticClass:"mo"},[_c('a',{attrs:{"href":"https://www.gosty.co.kr/boardingpass?utm_source=prestigegorilla&utm_medium=banner&utm_campaign=web","target":"_blank"}},[_c('img',{attrs:{"src":"https://cdn.prestigegorilla.net/post/2272/contents/19774/gosty_m.jpg"}})])])]),_c('div',{staticClass:"box_basic box_roll"},[_c('div',{staticClass:"container ctnr_dark"},[_c('display-container',{attrs:{"section":65,"align":"left"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var component = ref.component;
var row = ref.row;
return _c('div',{},[_c('p',{staticClass:"img_wrap"},[_c('img',{attrs:{"src":component.getImage(row)}})]),_c('div',{staticClass:"con_tit"},[_vm._v(_vm._s(component.getTitle(row))+" "),_c('p',{staticClass:"s_txt1"},[_vm._v(_vm._s(component.getText1(row)))]),_c('p',{staticClass:"s_txt2"},[_vm._v(_vm._s(component.getText2(row)))])])])}}])})],1)]),_c('div',{staticClass:"con_add box_basic"},[_c('p',{staticClass:"tit_l"},[_vm._v("프고가 테마별 베스트만 뽑았어요~")]),_c('ul',{staticClass:"tag"},[_c('li',[_vm._v("루프탑바")])]),_c('div',{staticClass:"scroller"},[_c('div',{staticClass:"inner inner_s num-5"},[_c('display-container',{attrs:{"section":73,"align":"left"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var component = ref.component;
var row = ref.row;
return _c('div',{staticClass:"container ctnr_s"},[_c('p',{staticClass:"img_wrap"},[_c('img',{attrs:{"src":component.getImage(row)}})]),_c('p',{staticClass:"name_kr"},[_vm._v(_vm._s(component.getName(row)))]),_c('p',{staticClass:"name_en"},[_vm._v(_vm._s(component.getNameEng(row)))])])}}])}),_c('display-container',{attrs:{"section":74,"align":"left"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var component = ref.component;
var row = ref.row;
return _c('div',{staticClass:"container ctnr_s"},[_c('p',{staticClass:"img_wrap"},[_c('img',{attrs:{"src":component.getImage(row)}})]),_c('p',{staticClass:"name_kr"},[_vm._v(_vm._s(component.getName(row)))]),_c('p',{staticClass:"name_en"},[_vm._v(_vm._s(component.getNameEng(row)))])])}}])}),_c('display-container',{attrs:{"section":75,"align":"left"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var component = ref.component;
var row = ref.row;
return _c('div',{staticClass:"container ctnr_s"},[_c('p',{staticClass:"img_wrap"},[_c('img',{attrs:{"src":component.getImage(row)}})]),_c('p',{staticClass:"name_kr"},[_vm._v(_vm._s(component.getName(row)))]),_c('p',{staticClass:"name_en"},[_vm._v(_vm._s(component.getNameEng(row)))])])}}])}),_c('display-container',{attrs:{"section":76,"align":"left"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var component = ref.component;
var row = ref.row;
return _c('div',{staticClass:"container ctnr_s"},[_c('p',{staticClass:"img_wrap"},[_c('img',{attrs:{"src":component.getImage(row)}})]),_c('p',{staticClass:"name_kr"},[_vm._v(_vm._s(component.getName(row)))]),_c('p',{staticClass:"name_en"},[_vm._v(_vm._s(component.getNameEng(row)))])])}}])}),_c('display-container',{attrs:{"section":77,"align":"left"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var component = ref.component;
var row = ref.row;
return _c('div',{staticClass:"container ctnr_s"},[_c('p',{staticClass:"img_wrap"},[_c('img',{attrs:{"src":component.getImage(row)}})]),_c('p',{staticClass:"name_kr"},[_vm._v(_vm._s(component.getName(row)))]),_c('p',{staticClass:"name_en"},[_vm._v(_vm._s(component.getNameEng(row)))])])}}])})],1)]),_c('ul',{staticClass:"tag mgn_top"},[_c('li',[_vm._v("펫캉스")])]),_c('div',{staticClass:"scroller"},[_c('div',{staticClass:"inner inner_s num-5"},[_c('display-container',{attrs:{"section":79,"align":"left"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var component = ref.component;
var row = ref.row;
return _c('div',{staticClass:"container ctnr_s"},[_c('p',{staticClass:"img_wrap"},[_c('img',{attrs:{"src":component.getImage(row)}})]),_c('p',{staticClass:"name_kr"},[_vm._v(_vm._s(component.getName(row)))]),_c('p',{staticClass:"name_en"},[_vm._v(_vm._s(component.getNameEng(row)))])])}}])}),_c('display-container',{attrs:{"section":80,"align":"left"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var component = ref.component;
var row = ref.row;
return _c('div',{staticClass:"container ctnr_s"},[_c('p',{staticClass:"img_wrap"},[_c('img',{attrs:{"src":component.getImage(row)}})]),_c('p',{staticClass:"name_kr"},[_vm._v(_vm._s(component.getName(row)))]),_c('p',{staticClass:"name_en"},[_vm._v(_vm._s(component.getNameEng(row)))])])}}])}),_c('display-container',{attrs:{"section":81,"align":"left"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var component = ref.component;
var row = ref.row;
return _c('div',{staticClass:"container ctnr_s"},[_c('p',{staticClass:"img_wrap"},[_c('img',{attrs:{"src":component.getImage(row)}})]),_c('p',{staticClass:"name_kr"},[_vm._v(_vm._s(component.getName(row)))]),_c('p',{staticClass:"name_en"},[_vm._v(_vm._s(component.getNameEng(row)))])])}}])}),_c('display-container',{attrs:{"section":82,"align":"left"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var component = ref.component;
var row = ref.row;
return _c('div',{staticClass:"container ctnr_s"},[_c('p',{staticClass:"img_wrap"},[_c('img',{attrs:{"src":component.getImage(row)}})]),_c('p',{staticClass:"name_kr"},[_vm._v(_vm._s(component.getName(row)))]),_c('p',{staticClass:"name_en"},[_vm._v(_vm._s(component.getNameEng(row)))])])}}])}),_c('display-container',{attrs:{"section":83,"align":"left"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var component = ref.component;
var row = ref.row;
return _c('div',{staticClass:"container ctnr_s"},[_c('p',{staticClass:"img_wrap"},[_c('img',{attrs:{"src":component.getImage(row)}})]),_c('p',{staticClass:"name_kr"},[_vm._v(_vm._s(component.getName(row)))]),_c('p',{staticClass:"name_en"},[_vm._v(_vm._s(component.getNameEng(row)))])])}}])})],1)])]),_c('div',{staticClass:"con_add box_basic last"},[_c('p',{staticClass:"tit_l"},[_vm._v("결정이 어렵다면, 여긴 어때요?")]),_c('div',{staticClass:"scroller"},[_c('div',{staticClass:"inner inner_m num-5"},[_c('display-container',{attrs:{"section":85,"align":"left"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var component = ref.component;
var row = ref.row;
return _c('div',{},[_c('a',{staticClass:"container ctnr_m ctnr_dark"},[_c('p',{staticClass:"img_wrap"},[_c('img',{attrs:{"src":component.getImage(row)}})]),_c('p',{staticClass:"con_tit"},[_c('i',[_c('em',[_vm._v(_vm._s(component.getCate(row)))]),_vm._v(_vm._s(component.getType(row)))]),_vm._v(_vm._s(component.getTitle(row)))])])])}}])}),_c('display-container',{attrs:{"section":86,"align":"left"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var component = ref.component;
var row = ref.row;
return _c('div',{},[_c('a',{staticClass:"container ctnr_m ctnr_dark"},[_c('p',{staticClass:"img_wrap"},[_c('img',{attrs:{"src":component.getImage(row)}})]),_c('p',{staticClass:"con_tit"},[_c('i',[_c('em',[_vm._v(_vm._s(component.getCate(row)))]),_vm._v(_vm._s(component.getType(row)))]),_vm._v(_vm._s(component.getTitle(row)))])])])}}])}),_c('display-container',{attrs:{"section":87,"align":"left"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var component = ref.component;
var row = ref.row;
return _c('div',{},[_c('a',{staticClass:"container ctnr_m ctnr_dark"},[_c('p',{staticClass:"img_wrap"},[_c('img',{attrs:{"src":component.getImage(row)}})]),_c('p',{staticClass:"con_tit"},[_c('i',[_c('em',[_vm._v(_vm._s(component.getCate(row)))]),_vm._v(_vm._s(component.getType(row)))]),_vm._v(_vm._s(component.getTitle(row)))])])])}}])}),_c('display-container',{attrs:{"section":88,"align":"left"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var component = ref.component;
var row = ref.row;
return _c('div',{},[_c('a',{staticClass:"container ctnr_m ctnr_dark"},[_c('p',{staticClass:"img_wrap"},[_c('img',{attrs:{"src":component.getImage(row)}})]),_c('p',{staticClass:"con_tit"},[_c('i',[_c('em',[_vm._v(_vm._s(component.getCate(row)))]),_vm._v(_vm._s(component.getType(row)))]),_vm._v(_vm._s(component.getTitle(row)))])])])}}])}),_c('display-container',{attrs:{"section":89,"align":"left"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var component = ref.component;
var row = ref.row;
return _c('div',{},[_c('a',{staticClass:"container ctnr_m ctnr_dark"},[_c('p',{staticClass:"img_wrap"},[_c('img',{attrs:{"src":component.getImage(row)}})]),_c('p',{staticClass:"con_tit"},[_c('i',[_c('em',[_vm._v(_vm._s(component.getCate(row)))]),_vm._v(_vm._s(component.getType(row)))]),_vm._v(_vm._s(component.getTitle(row)))])])])}}])})],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }