<script>
	import Airline from 'src/pc/pages/Airline'
  import PageLayout from '../layouts/PageLayout'
  import CommoMethods from 'src/_mixins/CommonMethods'
	import CommonComputed from 'src/_mixins/CommonComputed'
  import DisplayContainer from '../components/DisplayContainer'
	export default {
	  extends: Airline,
    components: {
      PageLayout,
			DisplayContainer
    }
  }
</script>


<style scoped>
  .airline .box_roll .container.ctnr_dark .con_tit .s_txt1 {
    font-size: 14px;
    font-weight: 400;
  }
  .airline .box_roll .container.ctnr_dark .con_tit .s_txt2 {
    font-size: 12px;
    font-weight: 400;
  }
  .airline .lst_company li {
    width: calc((100vw - 95px) / 3);
    display: inline-block;
    margin: 0 7.5px 22px;
    text-align: center;
  }
  .airline .lst_company li:nth-child(3n+1) {
    margin-left: 0;
  }
  .airline .lst_company li:nth-child(3n+3) {
    margin-right: 0;
  }
  .airline .lst_company li .img_logo {
    width: 100%;
    height: calc((100vw - 95px) / 3);
    border: 1px solid #e5e5e5;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .airline .lst_company li .img_logo img {
    width: 80%;
  }
  .airline .lst_company li .com_name {
    font-size: 13px;
    color: #222;
    letter-spacing: -0.05em;
    margin-top: 8px;
  }  
  .airline .box_basic .scroller .inner .display-container .container .con_tit i {
    display: none;
  }
</style>