<template>
  <page-layout class="airline">

		<!-- 롤링콘텐츠 -->
		<!--<div class="box_basic box_roll">-->
			<!--<router-link to="/">-->
				<!--<div class="container ctnr_dark">-->
					<!--<p class="img_wrap">-->
						<!--<img src="https://cdn.prestigegorilla.net/old/site/2021/1/517f6640-5ac5-11eb-b94e-81594ab0d0a7.jpg">-->
					<!--</p>-->
					<!--<p class="con_tit">4인 호캉스 즐길 수 있는 가성비 서울 호텔 BEST 10</p>-->
					<!--<ul class="pager">-->
						<!--<li class="on"></li>-->
						<!--<li></li>-->
						<!--<li></li>-->
						<!--<li></li>-->
					<!--</ul>-->
				<!--</div>-->
			<!--</router-link>-->
		<!--</div>-->
		<display-container :section="140" align="left" class="box_basic box_roll">
			<div slot-scope="{ component, row }" class="container ctnr_dark">
				<p class="img_wrap">
					<img :src="component.getImage(row)" class="fitimg">
				</p>
				<div class="con_tit">{{component.getTitle(row)}}
				  <p class="s_txt1">{{component.getText1(row)}}</p>
          <p class="s_txt2">{{component.getText2(row)}}</p>
				</div>
			</div>
		</display-container>
		<!-- //롤링콘텐츠 -->


		<!-- 카약 서치박스 -->
		<div class="kayakSchbox box_basic">
			<div id="kayakWidget3"
				data-affiliate-id="gorilladl"
				data-vertical="flight"
				data-vertical-list="flight"
				data-country-code="kr"
				data-currency-code="KRW"
				data-auto-height="true"
				data-language-code="KO"
				data-fill-trip-type="2"
				data-fill-location-from=""
				data-fill-location-to="">
			</div>
		</div>

		<!-- 항공사 -->
		<div class="box_basic">
			<p class="tit_l">항공사별 모아보기</p>
			<ul class="lst_company">
				<li v-for="(airline, i) in getAirlineCorpData" :key="airline.idx" v-show="isShowAllAirline || i < showAirlineLimitCnt">
					<router-link :to="`/airline/detail/${airline.idx}`">
						<p class="img_logo"><img :src="airline.logo_img.url" v-if="airline.logo_img"></p>
						<p class="com_name">{{airline.name}}</p>
					</router-link>
				</li>
			</ul>
			<a @click="setShowAllAirline" class="btn_more drtn_down" v-if="isMoreAirline && !isShowAllAirline">항공사 더 보기</a>
		</div>
		
		<!-- 고스티 배너 -->
    <div class="banner_gosty">
      <div class="pc">
        <a href="https://www.gosty.co.kr/boardingpass?utm_source=prestigegorilla&utm_medium=banner&utm_campaign=web" target="_blank">
          <img src="https://cdn.prestigegorilla.net/post/2272/contents/19775/gosty_pc.jpg">
        </a>
      </div>
      <div class="mo">
        <a href="https://www.gosty.co.kr/boardingpass?utm_source=prestigegorilla&utm_medium=banner&utm_campaign=web" target="_blank">
          <img src="https://cdn.prestigegorilla.net/post/2272/contents/19774/gosty_m.jpg">
        </a>
      </div>
    </div>

		<!-- 추가콘텐츠1 -->
    <div class="box_basic">
    	<p class="tit_l">✈ 코시국 항공 근황 😭</p>
    	<div class="scroller">
    		<div class="inner inner_m num-5">
					<display-container :section="142" align="left">
						<div slot-scope="{ component, row }" class="container ctnr_m ctnr_dark">
							<p class="img_wrap">
								<img :src="component.getImage(row)">
							</p>
              <p class="con_tit"><i><em>{{component.getCate(row)}}</em>{{component.getType(row)}}</i>{{component.getTitle(row)}}</p>
						</div>
					</display-container>
					<display-container :section="143" align="left">
						<div slot-scope="{ component, row }" class="container ctnr_m ctnr_dark">
							<p class="img_wrap">
								<img :src="component.getImage(row)">
							</p>
							<p class="con_tit">{{component.getTitle(row)}}</p>
						</div>
					</display-container>
					<display-container :section="144" align="left">
						<div slot-scope="{ component, row }" class="container ctnr_m ctnr_dark">
							<p class="img_wrap">
								<img :src="component.getImage(row)">
							</p>
							<p class="con_tit">{{component.getTitle(row)}}</p>
						</div>
					</display-container>
					<display-container :section="145" align="left">
						<div slot-scope="{ component, row }" class="container ctnr_m ctnr_dark">
							<p class="img_wrap">
								<img :src="component.getImage(row)">
							</p>
							<p class="con_tit">{{component.getTitle(row)}}</p>
						</div>
					</display-container>
					<display-container :section="146" align="left">
						<div slot-scope="{ component, row }" class="container ctnr_m ctnr_dark">
							<p class="img_wrap">
								<img :src="component.getImage(row)">
							</p>
							<p class="con_tit">{{component.getTitle(row)}}</p>
						</div>
					</display-container>
    		</div>
    	</div>
    </div>
    <!--// 추가콘텐츠1 -->

    <!-- 추가콘텐츠2 -->
    <div class="box_basic">
    	<p class="tit_l">LCC 프리미엄좌석 파헤치기👀</p>
    	<div class="scroller">
    		<div class="inner inner_m num-5">
					<display-container :section="148" align="left">
						<div slot-scope="{ component, row }" class="container ctnr_m ctnr_dark">
							<p class="img_wrap">
								<img :src="component.getImage(row)">
							</p>
							<p class="con_tit"><i>{{component.getCate(row)}}</i>{{component.getTitle(row)}}</p>
						</div>
					</display-container>
					<display-container :section="149" align="left">
						<div slot-scope="{ component, row }" class="container ctnr_m ctnr_dark">
							<p class="img_wrap">
								<img :src="component.getImage(row)">
							</p>
							<p class="con_tit">{{component.getTitle(row)}}</p>
						</div>
					</display-container>
					<display-container :section="150" align="left">
						<div slot-scope="{ component, row }" class="container ctnr_m ctnr_dark">
							<p class="img_wrap">
								<img :src="component.getImage(row)">
							</p>
							<p class="con_tit">{{component.getTitle(row)}}</p>
						</div>
					</display-container>
					<display-container :section="151" align="left">
						<div slot-scope="{ component, row }" class="container ctnr_m ctnr_dark">
							<p class="img_wrap">
								<img :src="component.getImage(row)">
							</p>
							<p class="con_tit">{{component.getTitle(row)}}</p>
						</div>
					</display-container>
					<display-container :section="152" align="left">
						<div slot-scope="{ component, row }" class="container ctnr_m ctnr_dark">
							<p class="img_wrap">
								<img :src="component.getImage(row)">
							</p>
							<p class="con_tit">{{component.getTitle(row)}}</p>
						</div>
					</display-container>
    		</div>
    	</div>
    </div>
    <!--// 추가콘텐츠2 -->

  </page-layout>
</template>

<script>
  import PageLayout from '../layouts/PageLayout'
  import CommoMethods from 'src/_mixins/CommonMethods'
	import CommonComputed from 'src/_mixins/CommonComputed'
  import DisplayContainer from '../components/DisplayContainer'
	import { mapGetters } from 'vuex'
  export default {
    name: "Airline",
    components: {
      PageLayout,
			DisplayContainer
    },
    metaInfo () {
      return {
        title: `${this.getAppName} : [항공사]`,
        titleTemplate: '%s',
        meta: [
          {vmid: 'description', name: 'description', content: '호텔 전문 에디터가 쉽게 알려주는 항공사의 모든 정보'},
          {vmid: 'image', name: 'image', content: '/statics/seo/seo.jpg'},
          {vmid: 'og:title', property: 'og:title', content: `[항공사] | ${this.getAppName}`},
          {vmid: 'og:description', property: 'og:description', content: '호텔 전문 에디터가 쉽게 알려주는 항공사의 모든 정보'},
          {vmid: 'og:image', property: 'og:image', content: '/statics/seo/seo.jpg'}
        ]
      }
    },
    data () {
      return {
        airlineCorpData: [
          {}, {}, {}, {}, {}, {}, {}, {}, {}
        ],
				showAirlineLimitCnt: 12,
        isShowAllAirline: false
      }
    },
		mounted () {
			let recaptchaScript = document.createElement('script')
			recaptchaScript.setAttribute('src', 'https://www.kayak.com/search-widget/script/direct/kayakWidget3')
			document.head.appendChild(recaptchaScript)
		},
    beforeMount () {
      this.$store.dispatch('setAirlineCorpData')
    },
		computed: {
      ...CommonComputed,
      ...mapGetters([
        'getAirlineCorpData',
				'getAirlineCorpDataCnt'
      ]),
      isMoreAirline () {
        return this.getAirlineCorpDataCnt > this.showAirlineLimitCnt
			}
		},
    methods: {
      ...CommoMethods,
      setShowAllAirline () {
        this.isShowAllAirline = !this.isShowAllAirline
			}
    }
  }
</script>

<style scoped>
  .airline .box_roll .container.ctnr_dark .con_tit .s_txt1 {
    font-size: 20px;
    font-weight: 700;
    letter-spacing: 0em;
  }
  .airline .box_roll .container.ctnr_dark .con_tit .s_txt2 {
    font-size: 12px;
    font-weight: 400;
  }
	.airline .lst_company li {
		width: calc((960px - 180px) / 6);
		display: inline-block;
		margin: 0 20px 30px 0;
		text-align: center;
	}
	.airline .lst_company li:nth-child(6n) {
		margin-right: 0;
	}
	.airline .lst_company li .img_logo {
		width: 100%;
		height: calc((960px - 180px) / 6);
		border: 1px solid #e5e5e5;
		border-radius: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.airline .lst_company li .img_logo img {
		width: 80%;
	}
	.airline .lst_company li .com_name {
		font-size: 15px;
		color: #222;
		letter-spacing: -0.05em;
		margin-top: 8px;
	}
  .airline .box_basic .scroller .inner .display-container:first-child {
    width: 100%;
  }
  .airline .box_basic .scroller .inner .display-container {
    width: calc(50% - 5px);
  }
  .airline .box_basic .scroller .inner .display-container .container .con_tit i {
    display: block;
    font-size: 13px;
    font-weight: 300;
    line-height: 1.0em;
    margin-bottom: 15px;
    letter-spacing: 0em;
    text-transform: uppercase;
  }
  .airline .box_basic .scroller .inner .display-container .container .con_tit i em {
    font-style: normal;
    /*padding-right: 18px;
    margin-right: 18px;
    border-right: 1px solid rgba(255,255,255,0.5);*/
  }
  .airline .box_basic .scroller .inner .display-container:nth-child(odd) {
    margin-right: 0;
  }
  .airline .box_basic .scroller .inner .display-container:first-child .ctnr_m .img_wrap {
    height: 44vmin;
  }
  .airline .box_basic .scroller .inner .display-container:first-child .ctnr_m .con_tit {
    font-size: 34px;
    font-weight: 300;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    transform: translate(-50%, -50%);
    text-align: center;
    width: 80%;
  }
</style>
