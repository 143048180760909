<template>
  <div>

    <!-- content -->
    <slot name="header" />

    <section :class="{'padding_top': !noPaddingTop}">
      <slot />
    </section>

    <slot name="footer" />
    <!-- //content -->

  </div>
</template>

<script>
  export default {
    name: "PageLayout",
    props: {
      noPaddingTop: {
        default: false,
        type: Boolean
      }
    },
    // activated () {
    //   this.$nextTick(function () {
    //     alert(0)
    //   })
    // },
    // created () {
    //   this.$nextTick(function () {
    //     alert(2)
    //   })
    // },
  }
</script>

<style scoped>
  .inner {
    padding: 0 !important;
  }
</style>
