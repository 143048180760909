<template>
  <page-layout class="hotel fr-view">

		<!-- 검색 -->
		<div class="box_basic box_sch">
			<p class="tit_l">호텔 검색</p>
      <p class="btn ipt_area" :class="{ sel: getSearchHotelQuery.region.length > 0 || getSearchHotelQuery.cate }" @click="searchHotelDialog.region = true">{{getRegionText}}</p>
      <p class="btn ipt_theme" :class="{ sel: getSearchHotelQuery.hashTags.length > 0 }" @click="searchHotelDialog.hashTags = true">{{getHashTagText}}</p>
      <input :value="getSearchHotelQueryKeyword" type="text" class="ipt_city" readonly="readonly" placeholder="호텔명으로 찾기" @click="searchHotelDialog.keyword = true">
			<a class="btn_confirm" :disabled="isDisabledSearchHotel" @click="goSearchHotel()">검색</a>
			<a class="btn_reset" @click="resetSearchHotel()">검색 초기화</a>
		</div>
		<!-- //검색 -->

		<!-- 검색 // 호컴api 미사용으로 블라인드-->
		<!--<div class="box_basic box_sch">
			<p class="tit_l">호텔 검색</p>
			<input :value="getSearchHotelQueryKeyword" type="text" class="ipt_city" readonly="readonly" placeholder="도시, 호텔 이름으로 검색해주세요." @click="$refs.modal.setSearchHotelDialog(true)">
			<input :value="getSearchHotelQueryCheckInOutText" type="text" class="ipt_date" readonly="readonly" placeholder="2월 22일(월) ~ 2월 23일(화)" @click="$refs.modal.setSearchTermDialog(true)">
			<input :value="getSearchHotelQueryOptionText()" type="text" class="ipt_person" readonly="readonly" placeholder="객실 1개, 투숙객 2명" @click="$refs.modal.setSearchOptionDialog(true)">
			<a class="btn_confirm" :disabled.trim="getSearchHotelQueryKeyword.length < 1" @click="goSearchHotel()">검색</a>
		</div>-->
		<!-- //검색 -->

		<!-- 지역 콘텐츠 // 파라미터 부재로 블라인드 -->
    <div class="box_basic con_circle">
    	<p class="tit_l">이곳으로 떠나보세요!</p>
    	<div class="scroller">
				<div class="inner inner_xs num-5">
					<display-container :section="67" align="left">
						<a slot-scope="{ component, row }" class="container ctnr_xs">
							<p class="img_wrap bdr_circle">
								<img :src="component.getImage(row)">
							</p>
							<p class="tit">{{component.getTitle(row)}}</p>
						</a>
					</display-container>
					<display-container :section="68" align="left">
						<a slot-scope="{ component, row }" class="container ctnr_xs">
							<p class="img_wrap bdr_circle">
								<img :src="component.getImage(row)">
							</p>
							<p class="tit">{{component.getTitle(row)}}</p>
						</a>
					</display-container>
					<display-container :section="69" align="left">
						<a slot-scope="{ component, row }" class="container ctnr_xs">
							<p class="img_wrap bdr_circle">
								<img :src="component.getImage(row)">
							</p>
							<p class="tit">{{component.getTitle(row)}}</p>
						</a>
					</display-container>
					<display-container :section="70" align="left">
						<a slot-scope="{ component, row }" class="container ctnr_xs">
							<p class="img_wrap bdr_circle">
								<img :src="component.getImage(row)">
							</p>
							<p class="tit">{{component.getTitle(row)}}</p>
						</a>
					</display-container>
					<display-container :section="71" align="left">
						<a slot-scope="{ component, row }" class="container ctnr_xs">
							<p class="img_wrap bdr_circle">
								<img :src="component.getImage(row)">
							</p>
							<p class="tit">{{component.getTitle(row)}}</p>
						</a>
					</display-container>
				</div>
			</div>
    </div>
    
    <!-- 고스티 배너 -->
    <div class="banner_gosty">
      <div class="pc">
        <a href="https://www.gosty.co.kr/boardingpass?utm_source=prestigegorilla&utm_medium=banner&utm_campaign=web" target="_blank">
          <img src="https://cdn.prestigegorilla.net/post/2272/contents/19775/gosty_pc.jpg">
        </a>
      </div>
      <div class="mo">
        <a href="https://www.gosty.co.kr/boardingpass?utm_source=prestigegorilla&utm_medium=banner&utm_campaign=web" target="_blank">
          <img src="https://cdn.prestigegorilla.net/post/2272/contents/19774/gosty_m.jpg">
        </a>
      </div>
    </div>

		<!-- 롤링콘텐츠 -->
		<div class="box_basic box_roll">
			<div class="container ctnr_dark">
				<display-container :section="65" align="left">
					<div slot-scope="{ component, row }">
						<p class="img_wrap">
							<img :src="component.getImage(row)">
						</p>
						<div class="con_tit">{{component.getTitle(row)}}
						  <p class="s_txt1">{{component.getText1(row)}}</p>
              <p class="s_txt2">{{component.getText2(row)}}</p>
						</div>
					</div>
				</display-container>
			</div>
		</div>
		<!-- //롤링콘텐츠 -->

    <!-- 추가콘텐츠1 -->
    <div class="con_add box_basic">
    	<p class="tit_l">프고가 테마별 베스트만 뽑았어요~</p>
			<ul class="tag">
				<li>루프탑바</li>
			</ul>
    	<div class="scroller">
				<div class="inner inner_s num-5">
					<display-container :section="73" align="left">
						<div slot-scope="{ component, row }" class="container ctnr_s">
							<p class="img_wrap">
								<img :src="component.getImage(row)">
							</p>
							<p class="name_kr">{{component.getName(row)}}</p>
							<p class="name_en">{{component.getNameEng(row)}}</p>
						</div>
					</display-container>
					<display-container :section="74" align="left">
						<div slot-scope="{ component, row }" class="container ctnr_s">
							<p class="img_wrap">
								<img :src="component.getImage(row)">
							</p>
							<p class="name_kr">{{component.getName(row)}}</p>
							<p class="name_en">{{component.getNameEng(row)}}</p>
						</div>
					</display-container>
					<display-container :section="75" align="left">
						<div slot-scope="{ component, row }" class="container ctnr_s">
							<p class="img_wrap">
								<img :src="component.getImage(row)">
							</p>
							<p class="name_kr">{{component.getName(row)}}</p>
							<p class="name_en">{{component.getNameEng(row)}}</p>
						</div>
					</display-container>
					<display-container :section="76" align="left">
						<div slot-scope="{ component, row }" class="container ctnr_s">
							<p class="img_wrap">
								<img :src="component.getImage(row)">
							</p>
							<p class="name_kr">{{component.getName(row)}}</p>
							<p class="name_en">{{component.getNameEng(row)}}</p>
						</div>
					</display-container>
					<display-container :section="77" align="left">
						<div slot-scope="{ component, row }" class="container ctnr_s">
							<p class="img_wrap">
								<img :src="component.getImage(row)">
							</p>
							<p class="name_kr">{{component.getName(row)}}</p>
							<p class="name_en">{{component.getNameEng(row)}}</p>
						</div>
					</display-container>
				</div>
			</div>
    	<ul class="tag mgn_top">
				<li>펫캉스</li>
			</ul>
    	<div class="scroller">
				<div class="inner inner_s num-5">
					<display-container :section="79" align="left">
						<div slot-scope="{ component, row }" class="container ctnr_s">
							<p class="img_wrap">
								<img :src="component.getImage(row)">
							</p>
							<p class="name_kr">{{component.getName(row)}}</p>
							<p class="name_en">{{component.getNameEng(row)}}</p>
						</div>
					</display-container>
					<display-container :section="80" align="left">
						<div slot-scope="{ component, row }" class="container ctnr_s">
							<p class="img_wrap">
								<img :src="component.getImage(row)">
							</p>
							<p class="name_kr">{{component.getName(row)}}</p>
							<p class="name_en">{{component.getNameEng(row)}}</p>
						</div>
					</display-container>
					<display-container :section="81" align="left">
						<div slot-scope="{ component, row }" class="container ctnr_s">
							<p class="img_wrap">
								<img :src="component.getImage(row)">
							</p>
							<p class="name_kr">{{component.getName(row)}}</p>
							<p class="name_en">{{component.getNameEng(row)}}</p>
						</div>
					</display-container>
					<display-container :section="82" align="left">
						<div slot-scope="{ component, row }" class="container ctnr_s">
							<p class="img_wrap">
								<img :src="component.getImage(row)">
							</p>
							<p class="name_kr">{{component.getName(row)}}</p>
							<p class="name_en">{{component.getNameEng(row)}}</p>
						</div>
					</display-container>
					<display-container :section="83" align="left">
						<div slot-scope="{ component, row }" class="container ctnr_s">
							<p class="img_wrap">
								<img :src="component.getImage(row)">
							</p>
							<p class="name_kr">{{component.getName(row)}}</p>
							<p class="name_en">{{component.getNameEng(row)}}</p>
						</div>
					</display-container>
				</div>
			</div>

    </div>
    <!--// 추가콘텐츠2 -->

    <!-- 추가콘텐츠3 -->
    <div class="con_add box_basic last">
    	<p class="tit_l">결정이 어렵다면, 여긴 어때요?</p>
    	<div class="scroller">
    		<div class="inner inner_m num-5">
					<display-container :section="85" align="left">
						<div slot-scope="{ component, row }">
							<a class="container ctnr_m ctnr_dark">
								<p class="img_wrap">
									<img :src="component.getImage(row)">
								</p>
								<p class="con_tit"><i><em>{{component.getCate(row)}}</em>{{component.getType(row)}}</i>{{component.getTitle(row)}}</p>
							</a>
						</div>
					</display-container>
					<display-container :section="86" align="left">
						<div slot-scope="{ component, row }">
							<a class="container ctnr_m ctnr_dark">
								<p class="img_wrap">
									<img :src="component.getImage(row)">
								</p>
								<p class="con_tit"><i><em>{{component.getCate(row)}}</em>{{component.getType(row)}}</i>{{component.getTitle(row)}}</p>
							</a>
						</div>
					</display-container>
					<display-container :section="87" align="left">
						<div slot-scope="{ component, row }">
							<a class="container ctnr_m ctnr_dark">
								<p class="img_wrap">
									<img :src="component.getImage(row)">
								</p>
								<p class="con_tit"><i><em>{{component.getCate(row)}}</em>{{component.getType(row)}}</i>{{component.getTitle(row)}}</p>
							</a>
						</div>
					</display-container>
					<display-container :section="88" align="left">
						<div slot-scope="{ component, row }">
							<a class="container ctnr_m ctnr_dark">
								<p class="img_wrap">
									<img :src="component.getImage(row)">
								</p>
								<p class="con_tit"><i><em>{{component.getCate(row)}}</em>{{component.getType(row)}}</i>{{component.getTitle(row)}}</p>
							</a>
						</div>
					</display-container>
					<display-container :section="89" align="left">
						<div slot-scope="{ component, row }">
							<a class="container ctnr_m ctnr_dark">
								<p class="img_wrap">
									<img :src="component.getImage(row)">
								</p>
								<p class="con_tit"><i><em>{{component.getCate(row)}}</em>{{component.getType(row)}}</i>{{component.getTitle(row)}}</p>
							</a>
						</div>
					</display-container>
    		</div>
    	</div>
    </div>
    <!--// 추가콘텐츠3 -->

  </page-layout>
</template>

<script>
  import PageLayout from '../layouts/PageLayout'
  import DisplayContainer from '../components/DisplayContainer'
	import { mapGetters } from 'vuex'
  import CommonComputed from "src/_mixins/CommonComputed";
  import CommonMethods from "src/_mixins/CommonMethods";
	export default {
    name: "Search",
    components: {
      PageLayout,
			DisplayContainer
    },
    metaInfo () {
      return {
        title: `${this.getAppName} : [검색] 내게 맞는 호텔찾기`,
        titleTemplate: '%s',
        meta: [
          {vmid: 'description', name: 'description', content: '지역과 테마 및 간단한 키워드검색으로 호텔찾기'},
          {vmid: 'image', name: 'image', content: '/statics/seo/seo.jpg'},
          {vmid: 'og:title', property: 'og:title', content: `[검색] 내게 맞는 호텔찾기 | ${this.getAppName}`},
          {vmid: 'og:description', property: 'og:description', content: '지역과 테마 및 간단한 키워드검색으로 호텔찾기'},
          {vmid: 'og:image', property: 'og:image', content: '/statics/seo/seo.jpg'}
        ]
      }
    },
		data () {
      return {
        search: {
          keyword: ''
        }
      }
		},
    created () {
      this.$store.dispatch('setHotelRegionData')
      this.$store.dispatch('setHotelHashTagData')
    },
    computed: {
      ...CommonComputed,
			...mapGetters([
        'getSearchHotelQuery',
        'getSearchHotelQueryKeyword',
				'searchHotelDialog',
        'getHotelRegionName',
        'getHotelHashTagName'
      ]),
      getAccess () {
        return 'app'
      },
      isDisabledSearchHotel () {
        return !this.getSearchHotelQuery.keyword.trim() && !this.getSearchHotelQuery.cate && this.getSearchHotelQuery.region.length < 1 && this.getSearchHotelQuery.hashTags.length < 1
      },
      getRegionText () {
        const cate = this.getSearchHotelQuery.cate
        const region = this.getSearchHotelQuery.region
        const cnt = region.length
        if (cnt < 1 && !cate) {
          return '지역 선택'
        } else {
          if (cate === 'OVERSEAS') {
            return '해외 전체'
          } else if (cate === 'DOMESTIC') {
            return '국내 전체'
          } else {
            const name = this.getHotelRegionName(region[0])
            if (cnt > 1) {
              return `${name} 외 ${cnt - 1}건`
            } else {
              return name
            }
          }
        }
      },
      getHashTagText () {
        const hashTags = this.getSearchHotelQuery.hashTags
        const cnt = hashTags.length
        if (cnt < 1) {
          return '테마 선택'
        } else {
          const name = this.getHotelHashTagName(hashTags[0])
          return (cnt > 1) ? `${name} 외 ${cnt - 1}건` : name
        }
      }
    },
		methods: {
      ...CommonMethods,
      goSearchHotel () {
        if (!this.isDisabledSearchHotel) {
          // this.$store.dispatch('setSearchHotelQuery', this.cloneData(this.search))
          if (!this.isActivatedRoute) this.$router.push('/search/hotel')
        }
      },
      resetSearchHotel () {
        this.$parent.$refs.modal.resetSearchHotel()
      }
		}
  }
</script>

<style scoped>
  .box_basic.con_circle .scroller .inner .display-container {
    margin-right: 20px;
    width: unset !important;
  }
  .box_basic.con_circle .scroller .inner .display-container:last-child {
    margin-right: 0;
  }
  .box_basic.con_circle .scroller .inner .container .img_wrap {
    width: calc((960px - 160px) / 5);
    height: calc((960px - 160px) / 5);
  }
  .hotel .box_roll .container .img_wrap {
    padding-bottom: 46vmin;
  }
  .box_roll .container.ctnr_dark .con_tit .s_txt1 {
    font-size: 20px;
    font-weight: 700;
    letter-spacing: 0em;
  }
  .box_roll .container.ctnr_dark .con_tit .s_txt2 {
    font-size: 12px;
    font-weight: 400;
  }
	.con_add .tag {
		margin-bottom: 20px;
	}
	.con_add .tag li {
		display: inline-block;
		font-size: 15px;
		color: #222;
		font-weight: 700;
		letter-spacing: -0.03em;
		padding: 10px 20px;
		border: 1px solid #ddd;
	}
	.con_add .tag.mgn_top {
		margin-top: 35px;
	}
	.box_basic.box_sch {
    padding: 40px 40px;
	}
	.box_sch input[type=text] {
		width: calc((100% - 30px) / 4);
		height: 50px;
		/*background: #f1f1f1;*/
		background: #fff;
		border: 1px solid #ccc;
		margin-bottom: 10px;
		line-height: 50px;
		padding-left: 45px;
		font-size: 14px;
		color: #222;
		background-size: 18px 18px;
		background-position: 16px center;
		background-repeat: no-repeat;
    margin-right: 10px;
	}
	.box_sch input[type=text].ipt_city {
		background-image: url('/statics/img/ico_sch_sch.png');
	}
	.box_sch input[type=text].ipt_area {
		background-image: url('/statics/img/ico_sch_area.png');
	}
	.box_sch input[type=text].ipt_theme {
		background-image: url('/statics/img/ico_sch_theme.png');
    margin-right: 0;
	}
  .box_sch p.btn {
    display: inline-block;
    line-height: 50px;
    font-weight: 700;
    width: calc((100% - 30px) / 4);
    height: 50px;
    text-align: center;
    /*background-color: #ccf9f4;*/
    background-color: #fff;
    background-size: 18px 18px;
    background-position: 6.5% center;
		background-repeat: no-repeat;
    border: 1px solid #ccc;
    color: #222;
    cursor: pointer;
    margin-right: 10px;
    filter: grayscale(1.0);
  }
  .box_sch p.btn.on {
    background-color: #40b8b3;
    color: #007869;
  }
  .box_sch p.btn:hover {
    background-color: #f5f5f5;
  }
  .box_sch p.btn.sel {
    background-color: #eefaf9;
    border: 1px solid #00897a;
    color: #00897a;
    filter: grayscale(0.0);
  }
	.box_sch .ipt_area {
		background-image: url('/statics/img/ico_sch_area.png');
	}
	.box_sch .ipt_theme {
		background-image: url('/statics/img/ico_sch_theme.png');
	}
	::placeholder {
		font-size: 13px;
		color: #888;
	}
	.box_sch .btn_confirm {
		font-size: 16px;
		font-weight: 700;
		color: #fff;
		background: #0e6057;
		display: inline-block;
		text-align: center;
		width: calc((100% - 30px) / 4);
		height: 50px;
		line-height: 50px;
	}
	.box_sch .btn_reset {
    display: block;
    font-weight: 700;
    color: #666;
    margin-top: 20px;
    float: right;
	}
	.box_sch .btn_reset:before {
    content: "";
    display: inline-block;
    width: 16px;
    height: 19px;
    background-image: url('/statics/img/icon_reset.png');
    background-repeat: no-repeat;
    margin-right: 6px;
	}
  .hotel .box_basic .scroller .num-5 .display-container {
    width: calc((100% - 40px) / 5);
  }
  .hotel .box_basic .scroller .num-5 .display-container:last-child {
    margin-right: 0;
  }
  .hotel .box_basic .scroller .inner_m .display-container {
    width: calc((100% - 10px) / 2);
  }
  .hotel .box_basic .scroller .inner_m .display-container:first-child {
    width: 100%;
  }
  .hotel .box_basic .scroller .inner_m .display-container:first-child .ctnr_m .img_wrap {
    height: 44vmin;
  }
  .hotel .box_basic .scroller .inner_m .display-container:nth-child(odd) {
    margin-right: 0;
  }
  .hotel .box_basic .scroller .inner_m .display-container:first-child .con_tit {
    font-size: 34px;
    font-weight: 300;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    transform: translate(-50%, -50%);
    text-align: center;
    width: 80%;
  }
  .hotel .box_basic .scroller .inner .display-container .container .con_tit i {
    display: block;
    font-size: 13px;
    font-weight: 300;
    line-height: 1.0em;
    margin-bottom: 15px;
    letter-spacing: 0em;
    text-transform: uppercase;
  }
  .hotel .box_basic .scroller .inner .display-container .container .con_tit i em {
    font-style: normal;
    /*padding-right: 18px;
    margin-right: 18px;
    border-right: 1px solid rgba(255,255,255,0.5);*/
  }
</style>
