<script>
  import PageLayout from 'src/pc/layouts/PageLayout'
  export default {
    extends: PageLayout
  }
</script>

<style scoped>
  .inner {
    padding: 0 !important;
  }
</style>
