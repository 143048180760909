<script>
	import Search from 'src/pc/pages/Search'
  import PageLayout from '../layouts/PageLayout'
  import DisplayContainer from '../components/DisplayContainer'
	export default {
    extends: Search,
    components: {
      PageLayout,
			DisplayContainer
    }
  }
</script>

<style scoped>

  .box_roll .container.ctnr_dark .con_tit .s_txt1 {
    font-size: 14px;
    font-weight: 400;
  }
  .box_roll .container.ctnr_dark .con_tit .s_txt2 {
    font-size: 12px;
    font-weight: 400;
  }
  .con_add .tag {
    margin-bottom: 20px;
  }
  .con_add .tag li {
    display: inline-block;
    font-size: 13px;
    color: #222;
    font-weight: 700;
    letter-spacing: -0.03em;
    padding: 7px 15px;
    border: 1px solid #ddd;
  }
  .con_add .tag.mgn_top {
    margin-top: 35px;
  }
  .box_basic.box_sch {
    margin: 0 auto;
    padding: 0 32.5px 35px;
  }
  .box_sch input[type=text] {
    width: 100%;
    height: 50px;
    background: #fff;
    border: 0;
    margin-bottom: 10px;
    line-height: 50px;
    padding-left: 45px;
    font-size: 14px;
    color: #222;
    background-size: 18px 18px;
    background-position: 16px center;
    background-repeat: no-repeat;
    border: 1px solid #ccc;
  }
  .box_sch input[type=text].ipt_city {
    background-image: url('/statics/img/ico_sch_sch.png');
  }
  .box_sch input[type=text].ipt_area {
    background-image: url('/statics/img/ico_sch_area.png');
  }
  .box_sch input[type=text].ipt_theme {
    background-image: url('/statics/img/ico_sch_theme.png');
  }
  .box_sch p.btn {
    display: inline-block;
    line-height: 50px;
    font-size: 13px;
    font-weight: 700;
    width: 100%;
    height: 50px;
    text-align: center;
    background-color: #fff;
    background-size: 18px 18px;
    background-position: 15px center;
		background-repeat: no-repeat;
    border: 1px solid #ccc;
    color: #222;
    cursor: pointer;
    margin-bottom: 10px;
    filter: grayscale(1.0);
  }
  .box_sch p.btn.on {
    background-color: #40b8b3;
  }
  .box_sch p.btn.sel {
    background-color: #eefaf9;
    border: 1px solid #00897a;
    color: #00897a;
    filter: grayscale(0.0);
  }
	.box_sch .ipt_area {
		background-image: url('/statics/img/ico_sch_area.png');
	}
	.box_sch .ipt_theme {
		background-image: url('/statics/img/ico_sch_theme.png');
    margin-right: 0 !important;
    margin-bottom: 10px;
	}
  ::placeholder {
    font-size: 13px;
    color: #888;
  }
  .box_sch .btn_confirm {
    font-size: 16px;
    font-weight: 700;
    color: #fff;
    background: #0e6057;
    display: block;
    text-align: center;
    width: 100%;
    height: 50px;
    line-height: 50px;
  }
  .box_sch .btn_reset {
    display: block;
    font-size: 13px;
    font-weight: 500;
    color: #777;
    margin-top: 20px;
    float: right;
	}
	.box_sch .btn_reset:before {
    content: "";
    display: inline-block;
    width: 16px;
    height: 19px;
    background-image: url('/statics/img/icon_reset.png');
    background-repeat: no-repeat;
    background-size: contain;
    margin-right: 6px;
	}
  .hotel .box_basic .scroller .inner .display-container .container .con_tit i {
    display: block;
    font-size: 13px;
    font-weight: 300;
    line-height: 1.0em;
    margin-bottom: 15px;
    letter-spacing: 0em;
    text-transform: uppercase;
  }
  .hotel .box_basic .scroller .inner .display-container .container .con_tit i em {
    font-style: normal;
  }
</style>
