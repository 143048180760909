<template>
  <page-layout class="video">

    <!-- 플레이어 -->
		<!--<display-container :section="154" align="left">-->
			<!--<div slot-scope="{ component, row }" class="video_player">-->
				<!--<iframe :src="component.getVideo(row)" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>-->
			<!--</div>-->
		<!--</display-container>-->
		<div class="video_player">
			<iframe :src="main_video_src" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
		</div>

    <!-- 구독버튼 -->
    <div class="box_basic subscribe">
    	<a href="https://www.youtube.com/channel/UCsaJhHWL9GHEKfgdrBbw2YA?sub_confirmation=1" target="_blank" class="subsc_btn">Subscribe Our Channel</a>
    </div>

    <!-- 콘텐츠 리스트 -->
    <div class="curtn_lst">
    	<div class="box_basic box_tit">
    		<p class="tit_l">🔥 HOT VIDEO 🎥</p>
				<display-container :section="155" align="left" :no-auto-link="true">
					<a slot-scope="{ component, row }" class="video_curtn" @click="showVideo(row.model_data)" @load="showVideo(row.model_data)">
						<p class="img"><img :src="component.getImage(row)"></p>
						<p class="subj">{{component.getTitle(row)}}</p>
					</a>
				</display-container>
				<display-container :section="156" align="left" :no-auto-link="true">
					<a slot-scope="{ component, row }" class="video_curtn" @click="showVideo(row.model_data)" @load="showVideo(row.model_data)">
						<p class="img"><img :src="component.getImage(row)"></p>
						<p class="subj">{{component.getTitle(row)}}</p>
					</a>
				</display-container>
				<display-container :section="157" align="left" :no-auto-link="true">
					<a slot-scope="{ component, row }" class="video_curtn" @click="showVideo(row.model_data)" @load="showVideo(row.model_data)">
						<p class="img"><img :src="component.getImage(row)"></p>
						<p class="subj">{{component.getTitle(row)}}</p>
					</a>
				</display-container>
    		<!--<router-link to="/" class="video_curtn">-->
					<!--<p class="img"><img src="https://cdn.prestigegorilla.net/old/site/2021/4/480f8270-982c-11eb-8cb9-4325e38b02d9.jpg"></p>-->
					<!--<p class="subj">아시아나항공 A380 비즈니스 스위트석 국제선 무착륙비행 탑승기</p>-->
    		<!--</router-link>-->
    		<!--<router-link to="/" class="video_curtn">-->
					<!--<p class="img"><img src="https://cdn.prestigegorilla.net/old/site/2021/3/53c52460-8552-11eb-a947-89aa4e651e62.jpg"></p>-->
					<!--<p class="subj">아시아나항공 A380 비즈니스 스위트석 국제선 무착륙비행 탑승기</p>-->
    		<!--</router-link>-->
    		<!--<router-link to="/" class="video_curtn">-->
					<!--<p class="img"><img src="https://cdn.prestigegorilla.net/old/site/2021/3/9398d9d0-91e2-11eb-81ab-c3a20b90890c.jpg"></p>-->
					<!--<p class="subj">호텔속의 호텔 - 그랜드 인터컨티넨탈 파르나스 프레지덴셜 스위트룸</p>-->
    		<!--</router-link>-->
			</div>
		</div>

		<!-- 리스트 카테고리 -->
		<div class="lst_cate lst_cate_video box_basic">
			<a :class="{on : !type}" @click="setType('')">ALL</a>
			<a :class="{on : type === 'HOTEL'}" @click="setType('HOTEL')">HOTEL</a>
			<a :class="{on : type === 'FLIGHT'}" @click="setType('FLIGHT')">FLIGHT</a>
		</div>

		<!-- 리스트 -->
		<div class="box_basic video_lst" style="min-height: calc(130px * 5)">
			<q-infinite-scroll @load="onLoad" :offset="250">
				<a class="video_ctnr" v-for="video in videoData" :key="`${type}${video.idx}`" @click="showVideo(video)">
					<p class="img"><img :src="video.thumbnail"></p>
					<p class="subj">{{video.title}}</p>
				</a>
				<template v-slot:loading>
					<div class="row justify-center q-my-md">
						<q-spinner-dots color="primary" size="40px" />
					</div>
				</template>
			</q-infinite-scroll>
		</div>

  </page-layout>
</template>

<script>
  import PageLayout from '../layouts/PageLayout'
  import CommoMethods from 'src/_mixins/CommonMethods'
	import CommonComputed from 'src/_mixins/CommonComputed'
  import DisplayContainer from '../components/DisplayContainer'
  export default {
    name: "Video",
    components: {
      PageLayout,
			DisplayContainer
    },
    metaInfo () {
      return {
        title: `${this.getAppName} : [영상] 호텔&항공`,
        titleTemplate: '%s',
        meta: [
          {vmid: 'description', name: 'description', content: '프레스티지고릴라 에디터가 직접 경험한 럭셔리 항공·호텔 리뷰, 여행팁, 다양한 뉴스와 프로모션 소식 등을 확인해보세요.'},
          {vmid: 'image', name: 'image', content: '/statics/seo/seo.jpg'},
          {vmid: 'og:title', property: 'og:title', content: `${this.getAppName} : [영상] 호텔&항공`},
          {vmid: 'og:description', property: 'og:description', content: '프레스티지고릴라 에디터가 직접 경험한 럭셔리 항공·호텔 리뷰, 여행팁, 다양한 뉴스와 프로모션 소식 등을 확인해보세요.'},
          {vmid: 'og:image', property: 'og:image', content: '/statics/seo/seo.jpg'}
        ]
      }
    },
    data () {
      return {
        loaded: false,
        videoData: [],
        main_video_src: '',
				type: '',
				paging: {
          p: 1,
					perPage: 18,
					total: 0
				},
				idx: null
      }
    },
    created () {
			this.getVideoList()
    },
    activated() {
      this.idx = this.getRouteParams.idx
      if (this.idx) this.getVideoOnce()
    },
    deactivated() {
      this.showVideo(this.videoData[0], false)
    },
    computed: {
      ...CommonComputed
		},
    methods: {
      ...CommoMethods,
      getBoxClass (i) {
        return this.loaded ? `card_${((i+1)+10000).toString().substr(-2)}` : ''
      },
      getVideoList (done = null) {
				this.loaded = true
				const action = `/videos/search?type=${this.type}&perPage=${this.paging.perPage}&p=${this.paging.p}`
				this.$axios.get(action).then((rs) => {
					if (rs.data.data.length > 0) {
					  if (!this.main_video_src && !this.idx) this.showVideo(rs.data.data[0], false)
						this.videoData = this.videoData.concat(rs.data.data)
						this.paging.p ++
						this.paging.total = rs.data.total
						this.loaded = false
						setTimeout(() => {
              if (done) done()
            }, 2000)
					}
				})
      },
			getVideoOnce () {
        const action = `/videos/${this.idx}`
        this.$axios.get(action).then((rs) => {
          if (rs.data.idx) {
            this.showVideo(rs.data)
          }
        })
			},
			onLoad (index, done) {
        if (this.paging.total === 0 || this.videoData.length < this.paging.total) {
          this.getVideoList(done)
				} else {
          done()
				}
			},
			setType (type) {
        this.type = type
				this.videoData = []
				this.paging.p = 1
        this.paging.total = 0
				this.getVideoList()
			},
      applyCSS(color) {
        console.log(color)
        return
        let cssRule = `#vue-ribbon:before {background-color: ${this.color}}`
        let style = document.createElement("style")
        style.type = "text/css"
        //append the style node as child of current component
        this.$el.appendChild(style)
        style.innerHTML = cssRule
      },
			showVideo(video, changeRoute = true) {
        this.main_video_src = this.getVideo(video.url)
        console.log(video.idx)
        if (changeRoute) this.$router.replace({ name: 'VideoDetail', params: { idx: video.idx } })
        window.scrollTo(0,0)
			}
    }
  }
</script>

<style scoped>
	.video_player {
		position: relative;
    width: 100%;
		height: 0;
		padding-bottom: 56.25%;
	}
	.video_player iframe {
		position: absolute;
		width: 100%;
		height: 100%;
	}
	.subscribe {
		text-align: center;
		padding: 25px 32.5px 0;
		margin-bottom: 0;
    border-bottom: 0;
	}
	.subscribe .subsc_btn {
		display: inline-block;
		padding: 10px 20px;
		border-radius: 5px;
		background: #ee0000;
		font-size: 13px;
		font-weight: 500;
		color: #fff;
		line-height: 21px;
	}
	.subscribe .subsc_btn:before {
		content: "";
		display: inline-block;
		width: 20px;
		height: 20px;
		background: url('/statics/img/sns_youtube_white.png');
		background-size: cover;
		background-position: center;
		margin-right: 10px;
	}
	.curtn_lst .box_tit {
		padding-bottom: 30px;
    border-bottom: 8px solid #f2f2f2;
	}
	.curtn_lst .box_tit .tit_l {
		margin-bottom: 20px;
	}
	.curtn_lst .display-container {
		display: inline-block;
		padding: 10px 0;
    margin-right: 10px;
    width: calc((100% - 20px) / 3);
	}
	.curtn_lst .display-container:last-child {
		margin-right: 0;
	}
	.curtn_lst .video_curtn {
		padding: 10px 0;
	}
	.curtn_lst .video_curtn .img {
	}
	.curtn_lst .video_curtn .img img {
		width: 100%;
    height: auto;
	}
	.curtn_lst .video_curtn .subj {
		font-size: 17px;
		letter-spacing: -0.03em;
    margin-top: 10px;
	}
	.lst_cate_video {
		border-bottom: 2px solid #f2f2f2;
	}
	.video_lst {

	}
	.video_lst .video_ctnr {
		display: inline-block;
		width: calc((100% - 20px) / 3);
		padding-bottom: 40px;
    margin-right: 10px;
	}
	.video_lst .video_ctnr:nth-child(3n) {
		margin-right: 0;
	}
	.video_lst .video_ctnr .img {
    display: inline-block;
		width: 100%;
    height: calc(286px * 0.56);
    position: relative;
	}
	.video_lst .video_ctnr .img img {
		position: absolute;
    width: 100%;
    height: 100%;
	}
	.video_lst .video_ctnr .subj {
		font-size: 15px;
		letter-spacing: -0.03em;
		margin-top: 10px;
		word-break: break-all;
	}
</style>
